/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box2-heart-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3.75 0a1 1 0 00-.8.4L.1 4.2a.5.5 0 00-.1.3V15a1 1 0 001 1h14a1 1 0 001-1V4.5a.5.5 0 00-.1-.3L13.05.4a1 1 0 00-.8-.4zM8.5 4h6l.5.667V5H1v-.333L1.5 4h6V1h1zM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132"/>',
    },
});
